import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  FormGroup,
  Dropdown,
  FormLabel,
  Button,
  Form,
} from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import PropTypes from "prop-types";

import ChooseCopyOrNew from "../../components/captureGrades/modals/ChooseCopyOrNew";
import AddCriterian from "../../components/captureGrades/modals/AddCriterian";

const customStyles = {
  baseContainers: {
    backgroundColor: "rgb(255, 255, 255)",
  },
  firstCriterion: {
    border: "solid 1px #ececee",
    minHeight: "35px",
    verticalAlign: "middle",
    paddingBottom: "10px",
  },
  criterionBox: {
    border: "solid 1px #ececee",
    borderTop: "none",
    minHeight: "35px",
    paddingBottom: "10px",
  },
  brokenText: {
    whiteSpace: "nowrap",
    overflow: "clip",
    textOverflow: "ellipsis !important",
    maxWidth: "80%",
  },
  // Styles for the table of qualifications
  studentTitleNameBox: {
    border: "solid 1px #ececee",
    height: "41px",
    verticalAlign: "middle",
    paddingTop: "3px",
  },
  studentNameBox: {
    border: "solid 1px #ececee",
    borderTop: "none",
    height: "41px",
    verticalAlign: "middle",
  },
  criterionTitleBox: {
    border: "solid 1px #ececee",
    borderLeft: "none",
    height: "41px",
    verticalAlign: "middle",
    minWidth: "fit-content",
    // width: "max-content",
    textAlign: "-moz-center",
    paddingTop: "10px",
  },
  scoreBox: {
    border: "solid 1px #ececee",
    borderLeft: "none",
    borderTop: "none",
    textAlign: "-moz-center",
    height: "41px",
    verticalAlign: "middle",
    // minWidth: "100px",
    // width: "100px",
  },
};

const base = [
  {
    student_id: 12312,
    student_class_id: 535,
    student_name: "Juan Peréz",
    average: 10,
    grading_periods: [
      {
        id: 17655,
        score: "2",
        comment: "Examen",
        score_id: 60320,
      },
      {
        id: 17656,
        score: "2",
        comment: "Tareas",
        score_id: 60322,
      },
      {
        id: 17656,
        score: "2",
        comment: "Actividades clase",
        score_id: 60322,
      },
      {
        id: 17656,
        score: "2",
        comment: "Documentacion",
        score_id: 60322,
      },
    ],
    grade: 2,
    group: "F",
    program_name: null,
    program_level: null,
  },
  {
    student_id: 12312,
    student_class_id: 535,
    student_name: "Jose Perez Leon",
    average: 10,
    grading_periods: [
      {
        id: 17655,
        score: "2",
        comment: "Examen",
        score_id: 60320,
      },
      {
        id: 17656,
        score: "2",
        comment: "Tareas",
        score_id: 60322,
      },
      {
        id: 17656,
        score: "2",
        comment: "Actividades clase",
        score_id: 60322,
      },
      {
        id: 17656,
        score: "2",
        comment: "Documentacion",
        score_id: 60322,
      },
    ],
    grade: 2,
    group: "F",
    program_name: null,
    program_level: null,
  },
];

const CaptureGradesView = () => {
  const [t] = useTranslation(["global", "captureGrades"]);
  const history = useHistory();
  const params = useParams();
  const [criterionList, setCriterionList] = useState([]);
  const [studentList, setStudentListCriterionList] = useState([]);
  const [containerHeight, setContainerHeight] = useState(
    window.innerHeight - 250
  );

  // Modals variables
  const [showChooseCopyOrNew, setShowChooseCopyOrNew] = useState(false);
  const [showAddCriterian, setShowAddCriterian] = useState(false);
  const [criterianForEdit, setCriterianForEdit] = useState({});

  /* Interaction functions */

  const modifyEvaluationCriteria = (position) => {
    //TODO: consultar api GET v2/evaluation_criteria/criterial_by_class para determinar que modal mostrar

    // TODO: revisar flujos para determinar si este flujo es correcto
    // si params no esta vacio

    if (Object.keys(params).length > 0) {
      evaluateInputParameters();
    } else {
      let criterian = JSON.parse(JSON.stringify(criterionList[position]));
      setShowAddCriterian(true);
      setCriterianForEdit(criterian);
    }
  };

  /* Initial loading */
  useEffect(() => {
    // getCriterionList();
    setCriterionList([
      {
        grading_criterion_id: 1,
        grading_criterion_class_id: 1,
        name: "Tareas",
        evaluable: false,
        porcentage: 50,
        second_criteria: [
          {
            grading_criterion_id: 1,
            grading_criterion_class_id: 1,
            name: "Tarea 1",
            porcentage: 50,
          },
          {
            grading_criterion_id: 1,
            grading_criterion_class_id: 1,
            name: "Tarea 2",
            porcentage: 50,
          },
        ],
        third_criteria: [
          {
            grading_criterion_id: 1,
            grading_criterion_class_id: 1,
            name: "Ortografía",
            porcentage: 50,
          },
          {
            grading_criterion_id: 1,
            grading_criterion_class_id: 1,
            name: "Gramática",
            porcentage: 50,
          },
        ],
      },
      {
        grading_criterion_id: 1,
        grading_criterion_class_id: 1,
        name: "Examen",
        evaluable: true,
        porcentage: 50,
        second_criteria: [
          {
            grading_criterion_id: 1,
            grading_criterion_class_id: 1,
            name: "Tarea 1",
            porcentage: 50,
          },
          {
            grading_criterion_id: 1,
            grading_criterion_class_id: 1,
            name: "Tarea 2",
            porcentage: 50,
          },
        ],
        third_criteria: [
          {
            grading_criterion_id: 1,
            grading_criterion_class_id: 1,
            name: "Ortografía",
            porcentage: 50,
          },
          {
            grading_criterion_id: 1,
            grading_criterion_class_id: 1,
            name: "Gramática",
            porcentage: 50,
          },
        ],
      },
    ]);

    setStudentListCriterionList(base);
  }, []);

  useEffect(() => {
    // Evaluate props parameters
    if (Object.keys(params).length > 0) {
      evaluateInputParameters();
    }
  }, []);

  const evaluateInputParameters = () => {
    const { addCriterias, hasLevelCriteria, hasClassCriteria } = params;
    // TODO: recalibrar validacion de parametros con casoso de uso cuando se tengan datos reales
    if (
      addCriterias === "false" ||
      hasLevelCriteria === "false" ||
      hasClassCriteria === "true"
    ) {
      return;
    }
    setShowChooseCopyOrNew(true);
  };

  // Monitor screen resizing
  window.addEventListener("resize", () => {
    setContainerHeight(window.innerHeight - 250);
  });

  /**
   * Calculate the cell width of the grades
   * @param {number} totalCells
   * @returns
   */
  const calculateCellWidth = (totalCells = 0) => {
    const minWidth = "150px";
    const width = document.getElementById("containerGrades").offsetWidth;
    const spacesAndTitle = 248;
    const result = (width - spacesAndTitle) / totalCells;
    return result <= 100 ? minWidth : result + "px";
  };

  return (
    <Container fluid>
      <Row>
        <Col md={12}>
          <i className="bi bi-chevron-left"></i>
          <FormLabel role="button" onClick={() => history.goBack()}>
            {t("global:breadcrumds.goBack")}
          </FormLabel>
        </Col>
      </Row>
      {/* Filters */}
      <Row>
        <Col md={4} className="mt-3">
          <h3> {t("captureGrades:main.title")}</h3>
        </Col>
        <Col md={8}>
          {/* partial */}
          <FormGroup
            className="ms-3 float-lg-end"
            style={{ display: "inline-block" }}
          >
            <FormLabel>{t("captureGrades:main.partial")}</FormLabel>
            <Dropdown>
              <Dropdown.Toggle
                id="partial"
                variant="outline-secondary"
                style={{ minWidth: "150px" }}
                className="text-start"
              >
                {t("captureGrades:main.partial")}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {[{ label: "opt1", value: 6 }].map((option) => {
                  return (
                    <Dropdown.Item
                      key={option.value}
                      // onClick={}
                    >
                      {option.label}
                    </Dropdown.Item>
                  );
                })}
              </Dropdown.Menu>
            </Dropdown>
          </FormGroup>
          {/* period */}
          <FormGroup
            className="ms-3 float-lg-end"
            style={{ display: "inline-block" }}
          >
            <FormLabel> {t("captureGrades:main.period")}</FormLabel>
            <Dropdown>
              <Dropdown.Toggle
                id="period"
                variant="outline-secondary"
                style={{ minWidth: "150px" }}
                className="text-start"
              >
                {t("captureGrades:main.period")}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {[{ label: "opt1", value: 6 }].map((option) => {
                  return (
                    <Dropdown.Item
                      key={option.value}
                      // onClick={}
                    >
                      {option.label}
                    </Dropdown.Item>
                  );
                })}
              </Dropdown.Menu>
            </Dropdown>
          </FormGroup>
          {/* subject */}
          <FormGroup
            className="ms-3 float-lg-end"
            style={{ display: "inline-block" }}
          >
            <FormLabel>{t("captureGrades:main.subject")} </FormLabel>
            <Dropdown>
              <Dropdown.Toggle
                id="subject"
                variant="outline-secondary"
                style={{ minWidth: "150px" }}
                className="text-start"
              >
                {t("captureGrades:main.subject")}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {[{ label: "opt1", value: 6 }].map((option) => {
                  return (
                    <Dropdown.Item
                      key={option.value}
                      // onClick={}
                    >
                      {option.label}
                    </Dropdown.Item>
                  );
                })}
              </Dropdown.Menu>
            </Dropdown>
          </FormGroup>
        </Col>
      </Row>
      {/* CriterionList and grades */}
      <Row className="mt-4">
        {/* CriterionList */}
        <Col md={3} className="px-4">
          <Row>
            <Col
              md={12}
              style={{
                height: containerHeight,
                ...customStyles.baseContainers,
              }}
            >
              <Row className="mt-3">
                <Col md={12} classNamee="fw-bold">
                  <h5> {t("captureGrades:main.evaluationCriteria")}</h5>
                </Col>
              </Row>
              {/* List criteria */}
              <Row className="px-3">
                {criterionList.map((criterion, index) => {
                  return (
                    <Col
                      key={index}
                      md={12}
                      style={
                        index == 0
                          ? customStyles.firstCriterion
                          : customStyles.criterionBox
                      }
                    >
                      <Row>
                        <Col md={10} className="align-middle">
                          <b
                            className="d-inline-block mt-2 text-ellipsis"
                            style={customStyles.brokenText}
                          >
                            {criterion.name +
                              " - " +
                              criterion.porcentage +
                              "%"}
                          </b>{" "}
                          <b className="d-inline-block text-danger">
                            {criterion.second_criteria.length +
                              " " +
                              criterion.name}
                          </b>
                        </Col>
                        <Col
                          md={2}
                          className="float-end text-end"
                          style={{ marginTop: "7px" }}
                          onClick={() => modifyEvaluationCriteria(index)}
                        >
                          <i className="bi bi-pencil-fill" role="button" />
                        </Col>
                      </Row>
                      {/* Second level criteria block */}
                      {criterion.second_criteria.length > 0 &&
                        criterion.second_criteria.map(
                          (secondCriteria, index) => {
                            return (
                              <Row key={index}>
                                <Col md={10} className="align-middle">
                                  <b
                                    className="d-inline-block mt-2 text-ellipsis"
                                    style={customStyles.brokenText}
                                  >
                                    {secondCriteria.name +
                                      " - " +
                                      secondCriteria.porcentage +
                                      "%"}
                                  </b>
                                </Col>
                              </Row>
                            );
                          }
                        )}
                    </Col>
                  );
                })}
              </Row>
            </Col>
          </Row>
        </Col>
        {/* Grades */}
        <Col md={9} className="px-4">
          <Row>
            <Col
              md={12}
              id="containerGrades"
              className="px-4"
              style={{
                height: containerHeight,
                overflowX: "scroll",
                ...customStyles.baseContainers,
              }}
            >
              <Row className="mt-3" style={{ width: "max-content" }}>
                <Col
                  className="fw-bold"
                  style={customStyles.studentTitleNameBox}
                >
                  <b className="pt-2 d-inline-block" style={{ width: "200px" }}>
                    {t("captureGrades:main.studentName")}
                  </b>
                </Col>
                {studentList[0]?.grading_periods.map((grade, pos) => {
                  return (
                    <Col
                      key={pos}
                      style={{
                        width: calculateCellWidth(
                          studentList[0]?.grading_periods.length
                        ),
                        ...customStyles.criterionTitleBox,
                      }}
                      className="d-inline-block fw-bold"
                    >
                      <b className="mt-2"> {grade.comment}</b>
                    </Col>
                  );
                })}
              </Row>

              {studentList.map((student, index) => {
                return (
                  <Row key={index} style={{ width: "max-content" }}>
                    <Col
                      className="fw-bold"
                      style={customStyles.studentNameBox}
                    >
                      <p className="mt-2" style={{ width: "200px" }}>
                        {" "}
                        {student.student_name}
                      </p>
                    </Col>
                    {student.grading_periods.map((grade, pos) => {
                      return (
                        <Col
                          key={pos}
                          style={{
                            width: calculateCellWidth(
                              studentList[0]?.grading_periods.length
                            ),
                            ...customStyles.scoreBox,
                          }}
                          className="d-inline-block"
                        >
                          <Form.Control
                            name="score"
                            placeholder=""
                            value={grade.score}
                            // onBlur={ }
                            className="mt-2"
                            // onChange={}
                            style={{ width: "50px", height: "25px" }}
                          />
                        </Col>
                      );
                    })}
                  </Row>
                );
              })}
            </Col>
          </Row>
        </Col>
      </Row>
      {/* Btn save */}
      <Row className="mt-4">
        <Col md={12}>
          <Button
            id="downloadZipBtn"
            // disabled={isLoading}
            // onClick={}
            className="float-lg-end"
          >
            {t("global:buttons.save")}
          </Button>
        </Col>
      </Row>

      {/* Modals */}
      {showChooseCopyOrNew && (
        <ChooseCopyOrNew
          showChooseCopyOrNew={showChooseCopyOrNew}
          onHide={() => setShowChooseCopyOrNew(false)}
        />
      )}

      {showAddCriterian && (
        <AddCriterian
          showAddCriterian={showAddCriterian}
          onHide={() => {
            setShowAddCriterian(false);
            setCriterianForEdit({});
          }}
          criterianForEdit={criterianForEdit}
        />
      )}
    </Container>
  );
};

export default CaptureGradesView;
CaptureGradesView.propTypes = {
  match: PropTypes.object,
};
