// Import necessary libaries
import React, { useState, useEffect } from "react";
import { Button, Modal, Form, Card, Row, Col } from "react-bootstrap";
import PropTypes from "prop-types";
import Select from "react-select";
import { useTranslation } from "react-i18next";

// Custom Components
import selectStyles from "../../global/GlobalTools";
import TextLoading from "../../global/TextLoading";

export default function ModalGradingCriteria({
  openButtonStyle = {},
  schoolLevels = [],
}) {
  const [open, setOpen] = useState(false);
  const [t] = useTranslation(["global", "class"]);
  const [selectedLevel, setSelectedLevel] = useState(null);
  const [grandingCriteria, setGrandingCriteria] = useState([]);
  const [disabled, setDisabled] = useState(true);
  const [isSaving, setIsSaving] = useState(false);

  /**
   * Puporse: Select the school level
   * @param {obj} level
   */
  const selectLevel = (level) => {
    setSelectedLevel(level);
  };

  /**
   * Purpose: Close the modal and reset the state values
   */
  const handleOnclose = () => {
    setSelectedLevel(null);
    setGrandingCriteria([]);
    setOpen(false);
  };

  /**
   * Purpose: Add a new grading criteria
   */
  const addGradingCriteria = () => {
    const newCriteria = [
      ...grandingCriteria,
      {
        grading_criterion_id: null,
        name: "",
        evaluable: true,
        percentage: "",
      },
    ];
    // Set the new grading criteria
    setGrandingCriteria(newCriteria);
  };

  /**
   * Purpose: Remove a grading criteria
   * @param {*} index
   */
  const removeGradingCriteria = (index) => {
    const newCriteria = [...grandingCriteria];
    newCriteria.splice(index, 1);
    setGrandingCriteria(newCriteria);
  };

  /**
   * Purpose: Check if the grading criteria is disabled
   */
  useEffect(() => {
    // Check if the grading criteria is disabled
    if (grandingCriteria.length > 0) {
      // Check if the grading criteria has a name and percentage
      const disabled = grandingCriteria.some(
        (criteria) => !criteria.name || criteria.percentage <= 0
      );
      // Check if the total percentage is 100
      const initialValue = 0;
      const totalPercentage = grandingCriteria.reduce(
        (accumulator, currentValue) =>
          accumulator + (Number(currentValue?.percentage) || 0),
        initialValue
      );
      // Set the disabled state
      setDisabled(disabled || totalPercentage !== 100);
    }
  }, [grandingCriteria]);

  /**
   * Purpose: Save the grading criteria
   */
  const saveGradingCriteria = () => {
    setIsSaving(true);
    // TODO: Save the grading criteria - conect to the API
    setTimeout(() => {
      setIsSaving(false);
      handleOnclose();
    }, 2000);
  };

  /**
   * Purpose: Render the modal with the grading criteria form
   */
  return (
    <>
      {/**Open button dialog */}
      <Button
        variant="outline-secondary"
        style={{ ...openButtonStyle }}
        onClick={() => setOpen(true)}
      >
        {t("class:modalGrandingCriteria.title")}
      </Button>
      {/**Grading Criteria Modal */}
      {open && (
        <Modal show={open} bsSize="large" size="lg">
          {/**Modal header */}
          <Modal.Header className="modal-header">
            {/**Modal title */}
            <Modal.Title className="modal-title">
              {t("class:modalGrandingCriteria.title")}
            </Modal.Title>
            {/**Add grading criterial button */}
            <Button
              style={{ float: "right" }}
              variant="secondary"
              onClick={addGradingCriteria}
              disabled={!selectedLevel || isSaving}
            >
              <i className="bi bi-plus"></i>
              {t("class:modalGrandingCriteria.addCriteria")}
            </Button>
          </Modal.Header>
          {/**Modal body */}
          <Modal.Body>
            {/**School level select */}
            <Form.Group className="mb-3">
              <Form.Label>
                {t("class:modalGrandingCriteria.schoolLevelLabel")}
              </Form.Label>
              <Select
                classNamePrefix={"select"}
                styles={selectStyles}
                options={schoolLevels}
                noOptionsMessage={() => t("select.noMatches")}
                loadingMessage={() => t("select.searching")}
                isDisabled={!schoolLevels.length || isSaving}
                onChange={selectLevel}
              />
            </Form.Group>
            {/**Grading criteria description */}
            {grandingCriteria.length > 0 && (
              <p className="mb-3">
                {t("class:modalGrandingCriteria.criteriaDescription")}
              </p>
            )}
            {/**Grading criteria form */}
            {grandingCriteria.map((criteria, index) => (
              <Card
                className="mb-3"
                key={index}
                style={{ backgroundColor: "#F6F6F6" }}
              >
                <Card.Body>
                  <Row>
                    {/**Criteria level */}
                    <Col xs={12} md={6}>
                      <Form.Group className="mb-3">
                        <Form.Label>{`${index + 1} ${t(
                          "class:modalGrandingCriteria.criteriaLevel"
                        )}`}</Form.Label>
                      </Form.Group>
                    </Col>
                    {/**Criteria switch */}
                    <Col xs={12} md={6}>
                      <Form.Switch
                        className="float-end"
                        id={`switch-${index}`}
                        label={t(
                          "class:modalGrandingCriteria.formCriteriaSwitch"
                        )}
                        style={{ display: "flex", alignItems: "center" }}
                        onChange={(e) => {
                          const newCriteria = [...grandingCriteria];
                          newCriteria[index].evaluable = e.target.checked;
                          setGrandingCriteria(newCriteria);
                        }}
                        disabled={isSaving}
                      >
                        <span style={{ marginRight: "8px" }}>
                          {t("class:modalGrandingCriteria.formCriteriaSwitch")}
                        </span>
                        <Form.Check
                          type="switch"
                          id={`switch-${index}`}
                          checked={grandingCriteria[index].evaluable}
                          onChange={(e) => {
                            const newCriteria = [...grandingCriteria];
                            newCriteria[index].evaluable = e.target.checked;
                            setGrandingCriteria(newCriteria);
                          }}
                          disabled={isSaving}
                        />
                      </Form.Switch>
                    </Col>
                  </Row>
                  <Row>
                    {/**Criteria name */}
                    <Col xs={12} md={6}>
                      <Form.Group className="mb-3">
                        <Form.Label>
                          {t(
                            "class:modalGrandingCriteria.grandingCriteriaLabel"
                          )}
                        </Form.Label>
                        <Form.Control
                          type="text"
                          placeholder={t(
                            "class:modalGrandingCriteria.grandingCriteriaLabel"
                          )}
                          value={criteria.name}
                          onChange={(e) => {
                            const newCriteria = [...grandingCriteria];
                            newCriteria[index].name = e.target.value;
                            setGrandingCriteria(newCriteria);
                          }}
                          disabled={isSaving}
                        />
                      </Form.Group>
                    </Col>
                    {/**Criteria percentage */}
                    <Col xs={12} md={6}>
                      <Form.Group className="mb-3">
                        <Form.Label>
                          {t(
                            "class:modalGrandingCriteria.grandingCriteriapercentage"
                          )}
                        </Form.Label>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <Form.Control
                            type="number"
                            placeholder={t(
                              "class:modalGrandingCriteria.grandingCriteriapercentage"
                            )}
                            value={criteria.percentage}
                            onChange={(e) => {
                              const newCriteria = [...grandingCriteria];
                              newCriteria[index].percentage = e.target.value;
                              setGrandingCriteria(newCriteria);
                            }}
                            disabled={isSaving}
                          />
                          <i
                            className="bi bi-dash-circle-fill fa-lg"
                            style={{ marginLeft: "8px" }}
                            role="button"
                            onClick={() => removeGradingCriteria(index)}
                          />
                        </div>
                      </Form.Group>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            ))}
          </Modal.Body>
          {/**Modal footer */}
          <Modal.Footer>
            {/**Close and save buttons */}
            <Button
              variant="secondary"
              onClick={handleOnclose}
              disabled={isSaving}
            >
              Cerrar
            </Button>
            <Button
              variant="primary"
              onClick={saveGradingCriteria}
              disabled={disabled || isSaving}
            >
              {isSaving ? (
                <TextLoading
                  text={t("global:buttons.saving")}
                  variant="light"
                />
              ) : (
                t("global:buttons.save")
              )}
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </>
  );
}

ModalGradingCriteria.propTypes = {
  openButtonStyle: PropTypes.object,
  schoolLevels: PropTypes.array,
};
