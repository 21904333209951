import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  Modal,
  Button,
  Row,
  Col,
  Container,
  FormGroup,
  FormLabel,
  FormControl,
  FormCheck,
} from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { ReactSortable } from "react-sortablejs";

const customStyles = {
  container: {
    background: "#f6f6f6",
    borderRadius: "8px",
  },
  plusIcon: {
    cursor: "pointer",
    color: "#ea2c54",
    fontSize: "15px",
    marginLeft: "7px",
  },
  listIcon: {
    cursor: "n-resize",
    fontSize: "15px",
  },
  dashIcon: {
    cursor: "pointer",
    fontSize: "15px",
  },
  dropZone: {
    border: "1px solid #e7e7e7",
    padding: " 5px",
    borderRadius: "8px",
    // minHeight: "200px",
  },
};

const AddCriterian = (props) => {
  const [t] = useTranslation(["global", "captureGrades"]);
  const [criteriaData, setCriteriaData] = useState(
    props.criterianForEdit || {
      name: "",
      evaluable: false,
      porcentage: 0,
      second_criteria: [
        {
          grading_criterion_id: null,
          grading_criterion_class_id: null,
          name: "",
          porcentage: 100,
        },
      ],
    }
  );

  const handleChange = (e, position) => {
    let secondCriteria = criteriaData.second_criteria;
    const field = e.target.name;
    const value = e.target.value;
    // Validate percentage values entered
    if (field === "porcentage") {
      const limit = 100; // Mousquerramienta de porcentaje
      let isValid =
        (value.match(/^[0-9]*$/) == null ? false : true) && value <= limit;
      if (!isValid) return;
    }
    secondCriteria[position][field] = value;
    // update the state
    let dataUpdated = { ...criteriaData };
    dataUpdated.second_criteria = secondCriteria;
    setCriteriaData(dataUpdated);
  };

  const addSecondCriteria = () => {
    let secondCriteria = criteriaData.second_criteria;

    secondCriteria.push({
      grading_criterion_id: null,
      grading_criterion_class_id: null,
      name: "",
      porcentage: 0,
    });
    // Equalize percentages in equal parts
    secondCriteria = equalizePercentages(criteriaData.second_criteria);
    // update the state
    let dataUpdated = { ...criteriaData };
    dataUpdated.second_criteria = secondCriteria;
    setCriteriaData(dataUpdated);
  };

  const removeSecondCriteria = (index) => {
    let secondCriteria = criteriaData.second_criteria;
    secondCriteria.splice(index, 1);
    // Equalize percentages in equal parts
    secondCriteria = equalizePercentages(criteriaData.second_criteria);
    // update the state
    let dataUpdated = { ...criteriaData };
    dataUpdated.second_criteria = secondCriteria;
    setCriteriaData(dataUpdated);
  };

  const equalizePercentages = (criteria) => {
    // Divide 100% by the number of criteria to two decimal places.
    let porcentage = (100 / criteria.length).toFixed(2);
    // Remove decimals if they are 0
    porcentage = porcentage % 1 === 0 ? parseInt(porcentage) : porcentage;
    criteria.forEach((element) => {
      element.porcentage = porcentage;
    });
    return criteria;
  };

  return (
    <Modal show={props.showAddCriterian} size="lg">
      <Modal.Header>
        <Modal.Title className="modal-title">
          {t("captureGrades:modalAddCriterian.title")}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container style={customStyles.container} className="py-3">
          {/* 1st Level Title*/}
          <Row>
            <Col md={6}>
              <FormLabel>
                {t("captureGrades:modalAddCriterian.1stLevelOfCriteria")}{" "}
              </FormLabel>
            </Col>
            <Col md={6}>
              <FormCheck className="ms-1 d-inline form-switch float-end">
                {t("captureGrades:modalAddCriterian.considerRatingCalculation")}{" "}
                <FormCheck.Input
                  id="capture"
                  type="checkbox"
                  style={{ marginLeft: "5px" }}
                  // onClick={() => showArchivedProspect(!showArchived)}
                  checked={criteriaData.evaluable}
                  disabled
                />
              </FormCheck>
            </Col>
          </Row>
          {/* 1st Level Criterian */}
          <Row>
            <Col md={6}>
              <FormGroup>
                <FormLabel>
                  {t("captureGrades:modalAddCriterian.evaluationCriteria")}{" "}
                </FormLabel>
                <FormControl
                  name="nameFirstCriterian"
                  placeholder=""
                  value={criteriaData.name}
                  disabled
                  // onChange={}
                />
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <FormLabel>
                  {t("captureGrades:modalAddCriterian.evaluationRate")}{" "}
                </FormLabel>
                <FormControl
                  name="nameFirstCriterian"
                  placeholder=""
                  value={criteriaData.porcentage}
                  disabled
                  // onChange={}
                />
              </FormGroup>
            </Col>
          </Row>
          {/* 2nd Level Title*/}
          <Row className="mt-3">
            <Col md={12}>
              <FormLabel>
                {t("captureGrades:modalAddCriterian.2ndLevelOfCriteria")}
              </FormLabel>
              <i
                value="add"
                className="bi bi-plus-circle-fill"
                style={customStyles.plusIcon}
                onClick={addSecondCriteria}
              ></i>
            </Col>
          </Row>
          {/* 2nd Level Criterian */}
          <ReactSortable
            list={criteriaData.second_criteria}
            setList={(newState) =>
              setCriteriaData({ ...criteriaData, second_criteria: newState })
            }
            animation={200}
            delayOnTouchStart={true}
            removeOnSpill={false}
            handle=".bi-list"
          >
            {criteriaData.second_criteria.map((secondCriteria, index) => (
              <Row key={index}>
                <Col md={6}>
                  <Row>
                    {/* icon to sort */}
                    <Col md={1} style={{ paddingTop: "35px" }}>
                      <i
                        style={customStyles.listIcon}
                        className="bi bi-list"
                      ></i>
                    </Col>
                    <Col md={11}>
                      <FormGroup>
                        <FormLabel>
                          {t(
                            "captureGrades:modalAddCriterian.evaluationCriteria"
                          )}{" "}
                        </FormLabel>
                        <FormControl
                          name="name"
                          placeholder=""
                          value={secondCriteria.name}
                          onChange={(e) => handleChange(e, index)}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </Col>
                <Col md={6}>
                  <Row>
                    <Col md={11}>
                      <FormGroup>
                        <FormLabel>
                          {t("captureGrades:modalAddCriterian.evaluationRate")}
                        </FormLabel>
                        <FormControl
                          name="porcentage"
                          placeholder=""
                          value={secondCriteria.porcentage}
                          onChange={(e) => handleChange(e, index)}
                        />
                      </FormGroup>
                    </Col>
                    {/* icon to remove */}
                    <Col md={1} style={{ paddingTop: "35px" }}>
                      <i
                        style={customStyles.dashIcon}
                        className="bi bi-dash-circle-fill float-end"
                        onClick={() => removeSecondCriteria(index)}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
            ))}
          </ReactSortable>
        </Container>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="outline-secondary" onClick={() => props.onHide(false)}>
          {t("global:buttons.cancel")}
        </Button>
        <Button onClick={() => props.onHide(false)}>
          {t("global:buttons.save")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

AddCriterian.propTypes = {
  showAddCriterian: PropTypes.bool,
  onHide: PropTypes.func,
  criterianForEdit: PropTypes.object,
};

export default AddCriterian;
