import React from "react";
import { Col, Row, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

//Custom styles
const customStyles = {
  solidBackground: {
    background: "#FFFFFF",
    padding: "30px 20px",
    minHeight: "100%",
    border: "solid 1px #cccccc",
    borderRadius: "5px",
  },
};
export default function AssignmentDetails(props) {
  const [t] = useTranslation(["reportCards"]);

  /**
   * changes the selected cycle and fills in the dependent selectors
   * @param {obj} event event onchange
   */
  const changeCycleSelected = (event) => {
    const cycleId = event.target.value;
    const cycleSelected = props.schoolCycles.find(
      (cycle) => cycle.id === cycleId
    );
    cycleSelected.grades = [];
    cycleSelected.groups = [];
    //if there are levels, extract the grades
    if (cycleSelected.schoolLevels.length) {
      cycleSelected.grades = cycleSelected.schoolLevels[0].grades;
    }
    //if there are grades, extract the groups
    if (cycleSelected.grades.length) {
      cycleSelected.groups = cycleSelected.grades[0].groups;
    }
    props.groupByDevelopmentField();
    props.setCycleSelected(cycleSelected);
    props.setDefaulsClasses([]);
    props.cleanSelectedClasses();
    props.setHasDevelopmentAreas(false);
  };

  /**
   * changes the selected level and fills in the dependent selectors
   * @param {*} event event onchange
   */
  const changeLevelSelected = (event) => {
    let cycleSelected = { ...props.cycleSelected };
    cycleSelected.grades = [];
    cycleSelected.groups = [];
    cycleSelected.gradeSelected = 0;
    const levelId = event.target.value;
    const levelSelected = cycleSelected.schoolLevels.find(
      (level) => level.id === levelId
    );
    cycleSelected.levelSelected = levelSelected.id;
    cycleSelected.groupSelected = 0;
    cycleSelected.groupName = false;
    cycleSelected.hasDevelopmentAreas = levelSelected.has_development_areas;
    //if there are grades, extract the groups
    if (levelSelected.grades.length) {
      cycleSelected.grades = levelSelected.grades;
      // cycleSelected.gradeSelected = levelSelected.grades[0].id;
      // cycleSelected.gradeName = levelSelected.grades[0].grade_level;
      cycleSelected.groups = levelSelected.grades[0].groups;
    }
    if (levelSelected.has_development_areas == 1) {
      props.setHasDevelopmentAreas(true);
    } else {
      props.setHasDevelopmentAreas(false);
    }
    props.setCycleSelected(cycleSelected);
    props.setDefaulsClasses([]);
    props.cleanSelectedClasses();
  };
  /**
   * changes the selected grade and fills in the dependent selectors
   * @param {*} event event onchange
   */
  const changeGrade = (event) => {
    let cycleSelected = { ...props.cycleSelected };
    cycleSelected.groupSelected = 0;
    cycleSelected.groups = [];
    const gradeId = event.target.value;
    const gradeSelected = cycleSelected.grades.find(
      (grade) => grade.id === gradeId
    );
    cycleSelected.gradeSelected = gradeSelected.id;
    cycleSelected.gradeName = gradeSelected.grade_level;
    if (gradeSelected.groups.length) {
      cycleSelected.groups = gradeSelected.groups;
    }
    props.setCycleSelected(cycleSelected);
    props.setDefaulsClasses([]);
    props.cleanSelectedClasses();
  };

  /**
   * changes the selected group
   * @param {*} event event onchange
   */
  const changeGroup = (event) => {
    let cycleSelected = { ...props.cycleSelected };
    const groupId = event.target.value;
    cycleSelected.groupSelected = groupId;
    // get the filter for classes
    const groups = [...props.cycleSelected.groups];
    let found = groups.find((group) => group.id == groupId);
    cycleSelected.groupName = found.name;
    props.setCycleSelected(cycleSelected);
    let filter = {
      type: "gradeGroupLevelId",
      value: found.grade_group_grade_level_id,
    };
    props.getClass("", false, filter, []);
    props.setClassFilter(filter);
    let template = { ...props.templateInformation };
    template.gradeGroupGradeLevelId = found.grade_group_grade_level_id;
    props.setTemplateInformation(template);
    props.cleanSelectedClasses();
  };

  return (
    <Col md={12} style={customStyles.solidBackground}>
      <h5 className="mb-4 fw-bold">{t("reportCards:page.editSallotOf")}</h5>
      <Row>
        <Col md={12}>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>
              {t("reportCards:assignmentDetails.schoolCycle")}
            </Form.Label>
            <Form.Select
              aria-label="Default select example"
              onChange={changeCycleSelected}
              value={props.cycleSelected.id}
              disabled={props.availableAllocationValues}
            >
              {props.schoolCycles.map((cycle, index) => {
                return (
                  <option key={index} value={cycle.id}>
                    {cycle.name}
                  </option>
                );
              })}
            </Form.Select>
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>
              {t("reportCards:assignmentDetails.schooling")}
            </Form.Label>
            <Form.Select
              aria-label="Default select example"
              onChange={changeLevelSelected}
              value={props.cycleSelected.levelSelected}
              disabled={props.availableAllocationValues}
            >
              {props.cycleSelected.levelSelected == 0 && (
                <option value={0}>
                  {t("reportCards:assignmentDetails.selectLevel")}
                </option>
              )}
              {props.cycleSelected.schoolLevels.map((level, index) => {
                return (
                  <option key={index} value={level.id}>
                    {level.school_level_name}
                  </option>
                );
              })}
            </Form.Select>
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>{t("reportCards:assignmentDetails.grade")}</Form.Label>
            <Form.Select
              aria-label="Default select example"
              onChange={changeGrade}
              value={props.cycleSelected.gradeSelected}
              disabled={
                props.availableAllocationValues ||
                props.cycleSelected.levelSelected == 0
              }
            >
              {props.cycleSelected.gradeSelected == 0 && (
                <option value={0}>
                  {t("reportCards:assignmentDetails.textAll")}
                </option>
              )}
              {props.cycleSelected.grades.map((grade, index) => {
                return (
                  <option key={index} value={grade.id}>
                    {grade.grade_level}
                  </option>
                );
              })}
            </Form.Select>
          </Form.Group>
        </Col>
        <Col md={6}>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>{t("reportCards:assignmentDetails.group")}</Form.Label>
            <Form.Select
              aria-label="Default select example"
              value={props.cycleSelected.groupSelected}
              onChange={changeGroup}
              disabled={
                props.availableAllocationValues ||
                props.cycleSelected.gradeSelected == 0
              }
            >
              {props.cycleSelected.groupSelected == 0 && (
                <option value={0}>
                  {t("reportCards:assignmentDetails.textAll")}
                </option>
              )}
              {props.cycleSelected.groups.map((group, index) => {
                return (
                  <option key={index} value={group.id}>
                    {group.name}
                  </option>
                );
              })}
            </Form.Select>
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col md={9}>{t("reportCards:assignmentDetails.showObservations")}</Col>
        <Col md={3}>
          <Form.Check
            type="switch"
            id="custom-switch"
            checked={props.showObservations}
            onClick={() => {
              props.setShowObservations(!props.showObservations);
              props.setVisiblePage(!props.showObservations ? 2 : 1);
              let template = { ...props.templateInformation };
              (template.has_comments = !props.showObservations),
                props.setTemplateInformation(template);
            }}
          />
        </Col>
      </Row>
      {/*if the template has development areas, show the checkbox*/}
      {(props.hasDevelopmentAreas ||
        (props.cycleSelected?.hasDevelopmentAreas && props.cycleSelected?.hasDevelopmentAreas == 1)) && (
          <Row>
            <Col md={9}>
              {t("reportCards:assignmentDetails.checkboxDevelopmentAreas")}
            </Col>
            <Col md={3}>
              <Form.Check
                type="switch"
                id="custom-switch"
                checked={props.groupedByDevelopmentAreas}
                onClick={() => {
                  props.groupByDevelopmentField();
                }}
                disabled={!props.cycleSelected?.hasDevelopmentAreas || props.activeAveragingSelectionMode}
              />
            </Col>
          </Row>
        )}
    </Col>
  );
}

AssignmentDetails.propTypes = {
  schoolCycles: PropTypes.array,
  cycleSelected: PropTypes.array,
  setCycleSelected: PropTypes.func,
  showObservations: PropTypes.bool,
  setShowObservations: PropTypes.func,
  setVisiblePage: PropTypes.func,
  availableAllocationValues: PropTypes.bool,
  templateInformation: PropTypes.object,
  setTemplateInformation: PropTypes.func,
  classFilter: PropTypes.object,
  getClass: PropTypes.func,
  setDefaulsClasses: PropTypes.func,
  cleanSelectedClasses: PropTypes.func,
  setClassFilter: PropTypes.func,
  hasDevelopmentAreas: PropTypes.bool,
  groupedByDevelopmentAreas: PropTypes.bool,
  groupByDevelopmentField: PropTypes.func,
  setHasDevelopmentAreas: PropTypes.func,
  activeAveragingSelectionMode: PropTypes.bool,
};
