import React from "react";
import PropTypes from "prop-types";
import { Modal, Button, Row, Col, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";

const ChooseCopyOrNew = (props) => {
  const [t] = useTranslation(["global", "captureGrades"]);
  // Default optional props

  return (
    <Modal show={props.showChooseCopyOrNew}>
      <Modal.Header>
        <Modal.Title className="modal-title">
          {t("captureGrades:modalChoose.title")}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col md={12}> {t("captureGrades:modalChoose.message")}</Col>
        </Row>
        <Row className="mt-3">
          <Col md={12}>
            <Form.Select
              id="groupId"
              size="sm"
              // value={permissionsUser.groupId}
              // onChange={changePermissionsCombination}
            >
              <option value={"new"}>
                {t("captureGrades:modalChoose.addNewCriterion")}
              </option>
              <option value={"copy"}>
                {t("captureGrades:modalChoose.copyCriterion")}
              </option>
            </Form.Select>
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="outline-secondary" onClick={() => props.onHide(false)}>
          {t("global:buttons.cancel")}
        </Button>
        <Button>{t("captureGrades:modalChoose.next")}</Button>
      </Modal.Footer>
    </Modal>
  );
};
ChooseCopyOrNew.propTypes = {
  showChooseCopyOrNew: PropTypes.bool,
  onHide: PropTypes.func,
};
export default ChooseCopyOrNew;
